import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getData, patchData, patchForm, postData, postForm } from './base';
import Structure from './models/structure';
import Document from './models/document';
import Revision from './models/revision';
import Category from './models/category';
import Section from './models/section';
import DocumentType from './models/documentType';
import { IEntityQuery, useEntityQuery } from './entityQuery';
import { ApiOptions } from './options';
import ProcessorRule from './models/processorRule';
import DocumentHistory from './models/documentHistory';
import Configuration from './models/configuration';

const useSections = (id?: string) =>
	useEntityQuery<Section>({ name: 'sections', path: '/contents/sections', id });

const useConfigurations = (options: ApiOptions = { enabled: true, refetchOnMount: true }) => {
	return useEntityQuery<Configuration>({
		name: 'configurations',
		path: '/contents/configurations',
		...options,
	});
};

const useCategories = (options: ApiOptions = { enabled: true, refetchOnMount: true }) => {
	return useEntityQuery<Category>({
		name: 'categories',
		path: '/contents/category',
		...options,
	});
};

const useStructures = (options: ApiOptions = { enabled: false, refetchOnMount: true }) =>
	useEntityQuery<Structure>({
		name: 'structure',
		path: '/contents/structure',
		...options,
	});

const useProcessorRules = (id?: string) =>
	useEntityQuery<ProcessorRule>({
		name: 'processorRules',
		path: '/contents/processor/rules',
		id,
		enabled: true,
	});

const useDocuments = (options?: ApiOptions) => {
	const entityQuery = useEntityQuery<Document>({
		name: 'documents',
		path: '/contents/documents',
		tree: false,
		...options,
	});

	const query = {
		...entityQuery,
		upload: async (entity: Document) => {
			if (entity.id) {
				const { id } = entity;
				delete entity.id;
				const result = await patchForm(`/contents/documents/${id}/upload`, entity);
				return result.data;
			}
			const result = await postForm(`/contents/documents/upload`, entity);
			return result.data;
		},
		invalidate: async (id?: string) => {
			if (id) await getData(`/contents/documents/${id}/invalidate`);
			entityQuery.invalidate();
		},
	};

	return query;
};

const useDocumentContent = (id?: string) => {
	return useQuery(['document-content', id], getData(`/contents/documents/${id}/view`), {
		enabled: !!id,
	});
};

const useDocumentTypes = () =>
	useQuery<DocumentType[], Error>(['documentTypes'], getData(`/contents/documents/types`));

const useDocumentHistory = (id: String) => {
	return useQuery<DocumentHistory[], Error>(
		['history', id],
		getData(`/contents/documents/${id}/history`),
		{
			enabled: id !== undefined,
		},
	);
};

const useDocumentRevisions = (
	documentId: string | undefined,
	revisionId: string | undefined,
	options?: ApiOptions,
) => {
	const path = `/contents/documents/${documentId}/revisions`;
	const name = `revisions${documentId}`;
	const enabled = true;
	const refetchOnMount = true;
	return {
		useQuery: () => {
			const queryClient = useQueryClient();
			return {
				tree: useQuery<Revision[], Error>([name], getData(`${path}`), {
					refetchOnWindowFocus: false,
					refetchOnMount: false,
					enabled: false,
				}),
				all: useQuery<Revision[], Error>([name], getData(`${path}`), {
					refetchOnWindowFocus: true,
					refetchOnMount: true,
					enabled,
				}),
				query: useQuery<Revision[], Error>([name], getData(`${path}`), {
					refetchOnWindowFocus: false,
					refetchOnMount: false,
					enabled: false,
				}),
				one: useQuery<Revision, Error>(
					[name, revisionId],
					getData(`${path}/${revisionId}`),
					{
						refetchOnWindowFocus: true,
						enabled: revisionId !== undefined,
						refetchOnMount: true,
					},
				),
				save: async (entity: any) => {
					const result = await patchForm(`${path}/${entity.number}`, {
						label: entity.label,
						note: entity.note,
						startDate: `${entity.startDate}`,
						categories: entity.categories,
						file: entity.file,
					});
					return result.data;
				},
				invalidate: () => {
					queryClient.invalidateQueries([name]);
					if (revisionId) {
						queryClient.invalidateQueries([name, revisionId]);
					}
				},
			} as IEntityQuery<Revision>;
		},
		publish: async (revision: String) => {
			const result = await postData(
				`/contents/documents/${documentId}/publish/${revision}`,
				{},
			);
			if (result.status >= 299) {
				console.error('Error trying to publish.');
			}
		},
		unpublish: async (revision: String) => {
			const result = await postData(
				`/contents/documents/${documentId}/unpublish/${revision}`,
				{},
			);
			if (result.status >= 299) {
				console.error('Error trying to unpublish.');
			}
		},
	};
};

const useRelatedDocuments = (id: String | undefined) =>
	useQuery<Document[], Error>(
		['relatedDocuments', id],
		getData(`/contents/documents/${id}/relateds`),
		{
			enabled: id !== undefined,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

export default {
	useDocumentTypes,
	useRelatedDocuments,
	useDocuments,
	useStructures,
	useSections,
	useDocumentRevisions,
	useCategories,
	useProcessorRules,
	useDocumentHistory,
	useConfigurations,
	useDocumentContent,
};
